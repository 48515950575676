export const countriesStatesAndCities = {
  India: {
    "Andhra Pradesh": [
      "Visakhapatnam",
      "Vijayawada",
      "Guntur",
      "Nellore",
      "Kurnool",
    ],
    "Arunachal Pradesh": ["Itanagar", "Naharlagun", "Pasighat"],
    Assam: ["Guwahati", "Silchar", "Dibrugarh", "Jorhat", "Nagaon"],
    Bihar: ["Patna", "Gaya", "Bhagalpur", "Muzaffarpur", "Darbhanga"],
    Chhattisgarh: ["Raipur", "Bhilai", "Bilaspur", "Korba"],
    Goa: ["Panaji", "Vasco da Gama", "Margao"],
    Gujarat: ["Ahmedabad", "Surat", "Vadodara", "Rajkot", "Bhavnagar"],
    Haryana: ["Faridabad", "Gurgaon", "Hisar", "Panipat", "Karnal"],
    "Himachal Pradesh": ["Shimla", "Solan", "Dharamshala", "Una"],
    "Jammu and Kashmir": ["Srinagar", "Jammu", "Anantnag", "Baramulla"],
    Jharkhand: ["Ranchi", "Dhanbad", "Jamshedpur", "Bokaro"],
    Karnataka: ["Bangalore", "Mysore", "Hubli", "Mangalore", "Belgaum"],
    Kerala: ["Thiruvananthapuram", "Kochi", "Kozhikode", "Thrissur", "Kollam"],
    "Madhya Pradesh": ["Indore", "Bhopal", "Jabalpur", "Gwalior", "Ujjain"],
    Maharashtra: ["Mumbai", "Navi Mumbai", "Pune", "Nagpur", "Thane", "Nashik", "Palghar"],
    Manipur: ["Imphal", "Thoubal", "Bishnupur"],
    Meghalaya: ["Shillong", "Tura", "Jowai"],
    Mizoram: ["Aizawl", "Lunglei", "Champhai"],
    Nagaland: ["Kohima", "Dimapur", "Mokokchung"],
    Odisha: ["Bhubaneswar", "Cuttack", "Rourkela", "Sambalpur"],
    Punjab: ["Ludhiana", "Amritsar", "Jalandhar", "Patiala", "Bathinda"],
    Rajasthan: ["Jaipur", "Jodhpur", "Udaipur", "Kota", "Bikaner"],
    Sikkim: ["Gangtok", "Namchi", "Mangan"],
    "Tamil Nadu": [
      "Chennai",
      "Coimbatore",
      "Madurai",
      "Tiruchirappalli",
      "Salem",
    ],
    Telangana: ["Hyderabad", "Warangal", "Karimnagar", "Nizamabad"],
    Tripura: ["Agartala", "Udaipur", "Dharmanagar"],
    "Uttar Pradesh": ["Lucknow", "Kanpur", "Varanasi", "Agra", "Allahabad"],
    Uttarakhand: ["Dehradun", "Haridwar", "Nainital", "Roorkee"],
    "West Bengal": ["Kolkata", "Howrah", "Asansol", "Siliguri", "Durgapur"],
  },
  Dubai: {
    1: [
      "Abu Dhabi",
      "Ajman",
      "Al Ain",
      "Dubai",
      "Fujairah",
      "Ras Al Khaimah (RAK)",
      "Sharjah",
      "Umm Al Quwain (UAQ)",
    ],
  },
  Canada: {
    Alberta: ["Calgary", "Edmonton", "Red Deer", "Lethbridge", "Medicine Hat"],
    "British Columbia": [
      "Vancouver",
      "Victoria",
      "Kelowna",
      "Burnaby",
      "Surrey",
    ],
    Manitoba: [
      "Winnipeg",
      "Brandon",
      "Steinbach",
      "Portage la Prairie",
      "Thompson",
    ],
    "New Brunswick": [
      "Fredericton",
      "Saint John",
      "Moncton",
      "Miramichi",
      "Bathurst",
    ],
    "Newfoundland and Labrador": [
      "St. John's",
      "Mount Pearl",
      "Corner Brook",
      "Grand Falls-Windsor",
      "Labrador City",
    ],
    "Northwest Territories": [
      "Yellowknife",
      "Inuvik",
      "Hay River",
      "Fort Smith",
      "Norman Wells",
    ],
    "Nova Scotia": ["Halifax", "Dartmouth", "Sydney", "Truro", "New Glasgow"],
    Nunavut: [
      "Iqaluit",
      "Rankin Inlet",
      "Arviat",
      "Baker Lake",
      "Cambridge Bay",
    ],
    Ontario: ["Toronto", "Ottawa", "Mississauga", "Hamilton", "London"],
    "Prince Edward Island": [
      "Charlottetown",
      "Summerside",
      "Stratford",
      "Cornwall",
      "Montague",
    ],
    Quebec: ["Montreal", "Quebec City", "Laval", "Gatineau", "Longueuil"],
    Saskatchewan: [
      "Saskatoon",
      "Regina",
      "Prince Albert",
      "Moose Jaw",
      "North Battleford",
    ],
    Yukon: [
      "Whitehorse",
      "Dawson",
      "Watson Lake",
      "Haines Junction",
      "Carmacks",
    ],
  },
  Singapore: {
    Central: ["Singapore"],
    East: ["Bedok", "Changi", "Pasir Ris", "Tampines"],
    North: ["Sembawang", "Woodlands", "Yishun"],
    Northeast: ["Hougang", "Punggol", "Sengkang", "Serangoon"],
    West: [
      "Bukit Batok",
      "Bukit Panjang",
      "Choa Chu Kang",
      "Clementi",
      "Jurong East",
      "Jurong West",
      "Tengah",
    ],
  },
  Europe: {
    Albania: ["Tirana", "Durres", "Vlore"],
    Andorra: ["Andorra la Vella", "Encamp", "La Massana"],
    Austria: ["Vienna", "Salzburg", "Innsbruck"],
    Belarus: ["Minsk", "Gomel", "Mogilev"],
    Belgium: ["Brussels", "Antwerp", "Ghent"],
    "Bosnia and Herzegovina": ["Sarajevo", "Banja Luka", "Mostar"],
    Bulgaria: ["Sofia", "Plovdiv", "Varna"],
    Croatia: ["Zagreb", "Split", "Dubrovnik"],
    Cyprus: ["Nicosia", "Limassol", "Larnaca"],
    "Czech Republic": ["Prague", "Brno", "Ostrava"],
    Denmark: ["Copenhagen", "Aarhus", "Odense"],
    Estonia: ["Tallinn", "Tartu", "Narva"],
    Finland: ["Helsinki", "Tampere", "Turku"],
    France: ["Paris", "Marseille", "Lyon"],
    Germany: ["Berlin", "Munich", "Hamburg"],
    Greece: ["Athens", "Thessaloniki", "Heraklion"],
    Hungary: ["Budapest", "Debrecen", "Szeged"],
    Iceland: ["Reykjavik", "Akureyri", "Keflavik"],
    Ireland: ["Dublin", "Cork", "Galway"],
    Italy: ["Rome", "Milan", "Florence"],
    Latvia: ["Riga", "Daugavpils", "Liepaja"],
    Lithuania: ["Vilnius", "Kaunas", "Klaipeda"],
    Luxembourg: ["Luxembourg City", "Esch-sur-Alzette", "Dudelange"],
    Malta: ["Valletta", "Birkirkara", "Mosta"],
    Moldova: ["Chisinau", "Tiraspol", "Balti"],
    Monaco: ["Monaco-Ville"],
    Montenegro: ["Podgorica", "Budva", "Herceg Novi"],
    Netherlands: ["Amsterdam", "Rotterdam", "The Hague"],
    "North Macedonia": ["Skopje", "Bitola", "Ohrid"],
    Norway: ["Oslo", "Bergen", "Trondheim"],
    Poland: ["Warsaw", "Krakow", "Gdansk"],
    Portugal: ["Lisbon", "Porto", "Braga"],
    Romania: ["Bucharest", "Cluj-Napoca", "Timisoara"],
    Russia: ["Moscow", "Saint Petersburg", "Novosibirsk"],
    "San Marino": ["San Marino City"],
    Serbia: ["Belgrade", "Novi Sad", "Nis"],
    Slovakia: ["Bratislava", "Kosice", "Presov"],
    Slovenia: ["Ljubljana", "Maribor", "Celje"],
    Spain: ["Madrid", "Barcelona", "Valencia"],
    Sweden: ["Stockholm", "Gothenburg", "Malmö"],
    Switzerland: ["Zurich", "Geneva", "Bern"],
    Ukraine: ["Kyiv", "Kharkiv", "Odessa"],
    "United Kingdom": ["London", "Edinburgh", "Manchester"],
  },
  USA: {
    Alabama: ["Birmingham", "Montgomery", "Mobile", "Huntsville", "Tuscaloosa"],
    Alaska: ["Anchorage", "Fairbanks", "Juneau", "Sitka", "Ketchikan"],
    Arizona: ["Phoenix", "Tucson", "Mesa", "Chandler", "Scottsdale"],
    Arkansas: ["Little Rock", "Fort Smith", "Fayetteville", "Springdale", "Jonesboro"],
    California: ["Los Angeles", "San Francisco", "San Diego", "San Jose", "Sacramento"],
    Colorado: ["Denver", "Colorado Springs", "Aurora", "Fort Collins", "Boulder"],
    Connecticut: ["Hartford", "New Haven", "Stamford", "Waterbury", "Norwalk"],
    Delaware: ["Wilmington", "Dover", "Newark", "Middletown", "Smyrna"],
    Florida: ["Miami", "Orlando", "Tampa", "Jacksonville", "Fort Lauderdale"],
    Georgia: ["Atlanta", "Augusta", "Columbus", "Savannah", "Athens"],
    Hawaii: ["Honolulu", "Hilo", "Kailua", "Kahului", "Kihei"],
    Idaho: ["Boise", "Nampa", "Meridian", "Idaho Falls", "Pocatello"],
    Illinois: ["Chicago", "Aurora", "Rockford", "Joliet", "Naperville"],
    Indiana: ["Indianapolis", "Fort Wayne", "Evansville", "South Bend", "Carmel"],
    Iowa: ["Des Moines", "Cedar Rapids", "Davenport", "Sioux City", "Waterloo"],
    Kansas: ["Wichita", "Overland Park", "Kansas City", "Topeka", "Olathe"],
    Kentucky: ["Louisville", "Lexington", "Bowling Green", "Owensboro", "Covington"],
    Louisiana: ["New Orleans", "Baton Rouge", "Shreveport", "Lafayette", "Lake Charles"],
    Maine: ["Portland", "Lewiston", "Bangor", "South Portland", "Auburn"],
    Maryland: ["Baltimore", "Columbia", "Germantown", "Silver Spring", "Waldorf"],
    Massachusetts: ["Boston", "Worcester", "Springfield", "Lowell", "Cambridge"],
    Michigan: ["Detroit", "Grand Rapids", "Warren", "Sterling Heights", "Ann Arbor"],
    Minnesota: ["Minneapolis", "St. Paul", "Rochester", "Duluth", "Bloomington"],
    Mississippi: ["Jackson", "Gulfport", "Southaven", "Hattiesburg", "Biloxi"],
    Missouri: ["Kansas City", "St. Louis", "Springfield", "Independence", "Columbia"],
    Montana: ["Billings", "Missoula", "Great Falls", "Bozeman", "Butte"],
    Nebraska: ["Omaha", "Lincoln", "Bellevue", "Grand Island", "Kearney"],
    Nevada: ["Las Vegas", "Henderson", "Reno", "North Las Vegas", "Sparks"],
    NewHampshire: ["Manchester", "Nashua", "Concord", "Derry", "Dover"],
    NewJersey: ["Newark", "Jersey City", "Paterson", "Elizabeth", "Edison"],
    NewMexico: ["Albuquerque", "Las Cruces", "Rio Rancho", "Santa Fe", "Roswell"],
    NewYork: ["New York", "Buffalo", "Rochester", "Yonkers", "Syracuse"],
    NorthCarolina: ["Charlotte", "Raleigh", "Greensboro", "Durham", "Winston-Salem"],
    NorthDakota: ["Fargo", "Bismarck", "Grand Forks", "Minot", "West Fargo"],
    Ohio: ["Columbus", "Cleveland", "Cincinnati", "Toledo", "Akron"],
    Oklahoma: ["Oklahoma City", "Tulsa", "Norman", "Broken Arrow", "Lawton"],
    Oregon: ["Portland", "Eugene", "Salem", "Gresham", "Hillsboro"],
    Pennsylvania: ["Philadelphia", "Pittsburgh", "Allentown", "Erie", "Reading"],
    RhodeIsland: ["Providence", "Warwick", "Cranston", "Pawtucket", "East Providence"],
    SouthCarolina: ["Columbia", "Charleston", "North Charleston", "Mount Pleasant", "Rock Hill"],
    SouthDakota: ["Sioux Falls", "Rapid City", "Aberdeen", "Brookings", "Watertown"],
    Tennessee: ["Nashville", "Memphis", "Knoxville", "Chattanooga", "Clarksville"],
    Texas: ["Houston", "San Antonio", "Dallas", "Austin", "Fort Worth"],
    Utah: ["Salt Lake City", "West Valley City", "Provo", "West Jordan", "Orem"],
    Vermont: ["Burlington", "Essex", "South Burlington", "Colchester", "Rutland"],
    Virginia: ["Virginia Beach", "Norfolk", "Chesapeake", "Richmond", "Newport News"],
    Washington: ["Seattle", "Spokane", "Tacoma", "Vancouver", "Bellevue"],
    WestVirginia: ["Charleston", "Huntington", "Morgantown", "Parkersburg", "Wheeling"],
    Wisconsin: ["Milwaukee", "Madison", "Green Bay", "Kenosha", "Racine"],
    Wyoming: ["Cheyenne", "Casper", "Laramie", "Gillette", "Rock Springs"],
  },

  China: {
    Beijing: ["Beijing"],
    Shanghai: ["Shanghai"],
    Tianjin: ["Tianjin"],
    Chongqing: ["Chongqing"],
    Guangdong: ["Guangzhou", "Shenzhen", "Dongguan", "Foshan", "Zhongshan"],
    Zhejiang: ["Hangzhou", "Ningbo", "Wenzhou", "Jiaxing", "Huzhou"],
    Jiangsu: ["Nanjing", "Suzhou", "Wuxi", "Changzhou", "Nantong"],
    Shandong: ["Jinan", "Qingdao", "Yantai", "Weifang", "Jining"],
    Fujian: ["Fuzhou", "Xiamen", "Quanzhou", "Putian", "Zhangzhou"],
    Hubei: ["Wuhan", "Huangshi", "Shiyan", "Yichang", "Jingzhou"],
    Hunan: ["Changsha", "Zhuzhou", "Xiangtan", "Hengyang", "Yueyang"],
    Henan: ["Zhengzhou", "Luoyang", "Kaifeng", "Pingdingshan", "Anyang"],
    Hainan: ["Haikou", "Sanya", "Qionghai", "Wenchang", "Danzhou"],
    Hebei: ["Shijiazhuang", "Tangshan", "Baoding", "Handan", "Xingtai"],
    Liaoning: ["Shenyang", "Dalian", "Anshan", "Fushun", "Benxi"],
    Jilin: ["Changchun", "Jilin", "Siping", "Liaoyuan", "Tonghua"],
    Heilongjiang: ["Harbin", "Qiqihar", "Daqing", "Jiamusi", "Hegang"],
    Shanxi: ["Taiyuan", "Datong", "Yangquan", "Changzhi", "Jincheng"],
    Shaanxi: ["Xi'an", "Xianyang", "Weinan", "Yan'an", "Tongchuan"],
    Gansu: ["Lanzhou", "Jiayuguan", "Tianshui", "Baiyin", "Wuwei"],
    Qinghai: ["Xining", "Haidong", "Hainan", "Haixi", "Huangnan"],
    Tibet: ["Lhasa", "Shigatse", "Nagqu", "Ngari", "Nyingchi"],
    Xinjiang: ["Urumqi", "Karamay", "Turpan", "Kashgar", "Hotan"],
    InnerMongolia: ["Hohhot", "Baotou", "Ordos", "Chifeng", "Hulunbuir"],
    Ningxia: ["Yinchuan", "Shizuishan", "Wuzhong", "Guyuan", "Zhongwei"],
    Guangxi: ["Nanning", "Liuzhou", "Guilin", "Wuzhou", "Beihai"],
  },

  // Add Japan with prefectures and cities
  Japan: {
    Tokyo: ["Tokyo"],
    Kanagawa: ["Yokohama", "Kawasaki", "Sagamihara", "Yokosuka", "Fujisawa"],
    Osaka: ["Osaka", "Sakai", "Higashiosaka", "Minoo", "Toyonaka"],
    Aichi: ["Nagoya", "Toyota", "Ichinomiya", "Okazaki", "Seto"],
    Hokkaido: ["Sapporo", "Asahikawa", "Hakodate", "Tomakomai", "Obihiro"],
    Fukuoka: ["Fukuoka", "Kitakyushu", "Kurume", "Fukutsu", "Dazaifu"],
    Hyogo: ["Kobe", "Himeji", "Amagasaki", "Nishinomiya", "Akashi"],
    Kyoto: ["Kyoto", "Uji", "Kameoka", "Joyo", "Muko"],
    Chiba: ["Chiba", "Funabashi", "Ichikawa", "Matsudo", "Kashiwa"],
    Hiroshima: ["Hiroshima", "Fukuyama", "Kure", "Onomichi", "Mihara"],
    Ibaraki: ["Mito", "Hitachi", "Tsuchiura", "Kashima", "Tsukuba"],
    Niigata: ["Niigata", "Nagaoka", "Joetsu", "Sanjo", "Shibata"],
    Shizuoka: ["Shizuoka", "Hamamatsu", "Mishima", "Fuji", "Ito"],
    Okayama: ["Okayama", "Kurashiki", "Tsuyama", "Tamano", "Bizen"],
    Kumamoto: ["Kumamoto", "Yatsushiro", "Tamana", "Kikuchi", "Hitoyoshi"],
    Kagoshima: ["Kagoshima", "Kirishima", "Kanoya", "Akune", "Makurazaki"],
    Tochigi: ["Utsunomiya", "Ashikaga", "Kanuma", "Oyama", "Tochigi"],
    Gifu: ["Gifu", "Tajimi", "Gujo", "Hashima", "Mizuho"],
  },

  // Add Russia with federal subjects and cities
  Russia: {
    Moscow: ["Moscow"],
    SaintPetersburg: ["Saint Petersburg"],
    Novosibirsk: ["Novosibirsk"],
    Yekaterinburg: ["Yekaterinburg"],
    NizhnyNovgorod: ["Nizhny Novgorod"],
    Samara: ["Samara"],
    Omsk: ["Omsk"],
    Kazan: ["Kazan"],
    Chelyabinsk: ["Chelyabinsk"],
    RostovonDon: ["Rostov-on-Don"],
    Ufa: ["Ufa"],
    Volgograd: ["Volgograd"],
    Perm: ["Perm"],
    Krasnoyarsk: ["Krasnoyarsk"],
    Voronezh: ["Voronezh"],
    Saratov: ["Saratov"],
    Krasnodar: ["Krasnodar"],
    Tolyatti: ["Tolyatti"],
    Barnaul: ["Barnaul"],
    Izhevsk: ["Izhevsk"],
    Ulyanovsk: ["Ulyanovsk"],
    Vladivostok: ["Vladivostok"],
    Yaroslavl: ["Yaroslavl"],
    Irkutsk: ["Irkutsk"],
    Tyumen: ["Tyumen"],
    Khabarovsk: ["Khabarovsk"],
    Vladikavkaz: ["Vladikavkaz"],
    Makhachkala: ["Makhachkala"],
    Tula: ["Tula"],
    Orenburg: ["Orenburg"],
    Kemerovo: ["Kemerovo"],
    Novokuznetsk: ["Novokuznetsk"],
    Ryazan: ["Ryazan"],
    Tomsk: ["Tomsk"],
  },
  Pakistan: {
    Punjab: ["Lahore", "Faisalabad", "Rawalpindi", "Multan", "Gujranwala"],
    Sindh: ["Karachi", "Hyderabad", "Sukkur", "Larkana", "Mirpur Khas"],
    KhyberPakhtunkhwa: ["Peshawar", "Abbottabad", "Swat", "Mardan", "Nowshera"],
    Balochistan: ["Quetta", "Gwadar", "Turbat", "Khuzdar", "Chaman"],
    IslamabadCapitalTerritory: ["Islamabad"],
    AzadJammuandKashmir: ["Muzaffarabad", "Mirpur", "Bhimber", "Kotli", "Rawalakot"],
    GilgitBaltistan: ["Gilgit", "Skardu", "Chilas", "Ghizer", "Hunza"],
  },

  // Add South Africa with provinces and cities
  "South Africa": {
    Gauteng: ["Johannesburg", "Pretoria", "Centurion", "Midrand", "Sandton"],
    WesternCape: ["Cape Town", "Stellenbosch", "George", "Paarl", "Worcester"],
    KwaZuluNatal: ["Durban", "Pietermaritzburg", "Richards Bay", "Newcastle", "Ladysmith"],
    EasternCape: ["Port Elizabeth", "East London", "Mthatha", "Grahamstown", "Uitenhage"],
    FreeState: ["Bloemfontein", "Welkom", "Botshabelo", "Kroonstad", "Sasolburg"],
    Limpopo: ["Polokwane", "Mokopane", "Thohoyandou", "Lebowakgomo", "Musina"],
    Mpumalanga: ["Nelspruit", "Witbank", "Middelburg", "Secunda", "Ermelo"],
    NorthWest: ["Mahikeng", "Rustenburg", "Klerksdorp", "Potchefstroom", "Brits"],
    NorthernCape: ["Kimberley", "Upington", "Kuruman", "Springbok", "De Aar"],
  },

  // Add France with regions and cities
  France: {
    IledeFrance: ["Paris", "Versailles", "Boulogne-Billancourt", "Nanterre", "Créteil"],
    AuvergneRhoneAlpes: ["Lyon", "Grenoble", "Saint-Etienne", "Clermont-Ferrand", "Annecy"],
    ProvenceAlpesCotedAzur: ["Marseille", "Nice", "Toulon", "Aix-en-Provence", "Avignon"],
    HautsdeFrance: ["Lille", "Amiens", "Roubaix", "Calais", "Dunkerque"],
    GrandEst: ["Strasbourg", "Metz", "Nancy", "Mulhouse", "Reims"],
    NouvelleAquitaine: ["Bordeaux", "Limoges", "Pau", "La Rochelle", "Bayonne"],
    Occitanie: ["Toulouse", "Montpellier", "Nimes", "Perpignan", "Carcassonne"],
    BourgogneFrancheComte: ["Dijon", "Besancon", "Chalon-sur-Saone", "Belfort", "Nevers"],
    CentreValdeLoire: ["Orleans", "Tours", "Bourges", "Chartres", "Blois"],
    Normandy: ["Rouen", "Caen", "Le Havre", "Cherbourg", "Evreux"],
  },

  // Add Bangladesh with divisions and cities
  Bangladesh: {
    Dhaka: ["Dhaka", "Narayanganj", "Gazipur", "Tangail", "Mymensingh"],
    Chittagong: ["Chittagong", "Comilla", "Feni", "Cox's Bazar", "Chandpur"],
    Rajshahi: ["Rajshahi", "Bogra", "Pabna", "Nawabganj", "Joypurhat"],
    Khulna: ["Khulna", "Jessore", "Satkhira", "Bagerhat", "Chuadanga"],
    Barishal: ["Barishal", "Patuakhali", "Bhola", "Pirojpur", "Jhalokati"],
    Sylhet: ["Sylhet", "Moulvibazar", "Habiganj", "Sunamganj"],
    Rangpur: ["Rangpur", "Dinajpur", "Thakurgaon", "Kurigram", "Panchagarh"],
    Mymensingh: ["Mymensingh", "Jamalpur", "Netrokona", "Sherpur"],
  },

};

export const getCountries = () => {
  return Object.keys(countriesStatesAndCities).sort();
};

export const getStates = (country) => {
  return Object.keys(countriesStatesAndCities[country]).sort();
};

export const getAllCitiesOfACountry = (country) => {
  const arrays = Object.values(countriesStatesAndCities[country]);
  return arrays.flat(1).sort();
};
